const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Kode Program',
    dataIndex: 'program_code',
  },
  {
    title: 'Nama Program',
    dataIndex: 'program_name',
  },
  {
    title: 'Region Program',
    dataIndex: 'program_region',
  },
  {
    title: 'Kode Toko',
    dataIndex: 'code',
  },
  {
    title: 'Nama Toko',
    dataIndex: 'name',
  },
  {
    title: 'Alamat',
    dataIndex: 'address',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'No Telepon',
    dataIndex: 'phone',
  },
  {
    title: 'Nama Pemilik Toko',
    dataIndex: 'owner',
  },
  {
    title: 'Status Mapping',
    dataIndex: 'status_active_mapping',
    slots: { customRender: 'status' },
  },
  {
    title: 'Area Toko',
    dataIndex: 'area_name',
  },
  {
    title: 'Region Toko',
    dataIndex: 'region_name',
  },
  {
    title: 'Kode Distributor',
    dataIndex: 'distributor_code',
  },
  {
    title: 'Nama Distributor',
    dataIndex: 'distributor_name',
  },
  {
    title: 'OTP',
    dataIndex: 'otp',
  },
  {
    title: 'Status Registrasi',
    dataIndex: 'register_status',
  },
  {
    title: 'Tanggal & Waktu Registrasi',
    dataIndex: 'tanggal_registrasi',
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]

export default acolumns
