<template>
    <AModal
        :visible="visible"
        title="Detail History Mapping"
        width="90%"
        @cancel="handleModalCancel"
        :footer="null">
        <MdTable
            :columns="state.columns"
            :data-source="state.data"
            size="small"
            :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)">
            <template #action="{ record }">
                <ASpace>
                    <ATooltip title="Syncron">
                        <AButton
                            size="small"
                            :loading="record.isSync"
                            @click="btnSyncByID(record)">
                            <i class="fa fa-refresh"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        title="Edit">
                        <AButton
                            class="button"
                            size="small"
                            @click="btnForm(record)">
                            <i class="fe fe-edit" />
                        </AButton>
                    </ATooltip>
                </ASpace>
            </template>
        </MdTable>

        <!-- form -->
        <Form
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            v-model:item="visibleFormItemModal"
            @success="fetchDataList"/>
    </AModal>
</template>

<script>
import { defineComponent, reactive, onMounted, ref } from 'vue'
import { Modal, message } from 'ant-design-vue'
import apiClient from '@/services/axios'
import Form from './Form'

export default defineComponent({
    components: {
        Form,
    },
    props: {
        visible: [Boolean],
        items: {
            type: [Array],
            default: () => [],
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const state = reactive({
            data: [],
            columns: [
                {
                    title: 'Kode Program',
                    dataIndex: 'program_code',
                },
                {
                    title: 'Kode Toko',
                    dataIndex: 'vendor_code',
                },
                {
                    title: 'Tanggal Mulai',
                    dataIndex: 'start_date',
                },
                {
                    title: 'Tanggal Akhir',
                    dataIndex: 'end_date',
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    slots: { customRender: 'action' },
                },
            ],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:items', [])
        }

        const fetchDataList = (data) => {
            data.history_mapping.forEach((item) => {
                item.isSync = false
            })
            state.data = data.history_mapping
        }

        // sync data by id
        const btnSyncByID = (record = null) => {
            Modal.confirm({
                title: 'Konfirmasi Syncron Data',
                content: 'Apakah Anda ingin syncron data tersebut ?',
                onOk() {
                    record.isSync = true
                    apiClient
                        .get(`api/loyalty-program-stores/${record.id}/sync-poin`)
                        .then(({ data }) => {
                            message.success('Berhasil syncron')
                        })
                        .catch(e => message.error('Gagal syncron!'))
                        .finally(() => {
                            record.isSync = false
                        })
                },
                onCancel() { },
            })
        }

        // handle form
        const visibleFormModal = ref(false);
        const visibleFormItemModal = ref(null);

        const btnForm = (item = null, readOnly = false) => {
            visibleFormModal.value = true
            visibleFormItemModal.value = {
                ...item,
                readOnly,
            }
        }

        // handle vue
        onMounted(() => {
            props.items.forEach((item) => {
                item.isSync = false
            })

            state.data = props.items
        })

        return {
            handleModalCancel,
            state,
            fetchDataList,
            btnForm,
            visibleFormModal,
            visibleFormItemModal,
            btnSyncByID,
        }
    },
})
</script>